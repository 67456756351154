/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useConfig } from "@fitplan/context/lib-es/config";
import { GoogleOAuthProvider } from "@react-oauth/google";

import Header from "../header/header";
import Footer from "../footer/footer";
import "./layout.css";

const Layout = ({ children, ribbon = null, header, footer, hasBanner }) => {
  const config = useConfig();
  return <GoogleOAuthProvider clientId={config.googleApiClientId}>
    <Header ribbon={ribbon} menu={header} hasBanner={hasBanner} />
    <main>{children}</main>
    <Footer menus={footer} />
  </GoogleOAuthProvider>
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
