import React, { useState, useRef, useEffect } from "react";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { isValidHttpUrl, navigateTo } from "../../lib/UrlUtils";
import "./headerNavigation.scss";
import { gsap } from "gsap";
import useMount from "react-use/lib/useMount";
import { useAuthContext } from "@fitplan/context/lib-es/auth";

const HeaderNavigation = ({ menu }) => {
  const [active, setActive] = useState("");
  const navRef = useRef([]);
  const [isSSR, setIsSSR] = useState(true);
  const [isClient, setIsClient] = useState(false);
  useMount(() => setIsSSR(false));
  useEffect(() => {
    setIsClient(true);
  }, []);

  const authContext = useAuthContext();
  const onLogout = (event) => {
    event.preventDefault();
    authContext.logout();
    navigateTo("/");
  };

  const menuOpen = (e) => {
    setActive(!active);
    e.preventDefault();
    if (!active) {
      gsap.to(navRef.current, { opacity: 1, y: 0, stagger: 0.1 });
    } else {
      gsap.to(navRef.current, { opacity: 0, y: -30, stagger: 0.1 });
    }
  };
  const menuClose = (e) => {
    setActive("");
    e.preventDefault();
  };

  const { t, language } = useTranslation();
  const { languages, changeLanguage } = useI18next();

  const headerMenu = menu?.items?.map((menu, key) => {
    const headerLink = isValidHttpUrl(menu.link) ? (
      <a target="_blank" href={`${menu.link}`}>
        {menu.linkText}
      </a>
    ) : (
      <Link to={`/${menu.link}`}>{menu.linkText}</Link>
    );
    return (
      <li
        ref={(element) => {
          navRef.current[key] = element;
        }}
        key={menu.id}
      >
        {headerLink}
      </li>
    );
  });

  return (
    <React.Fragment key={isClient}>
      <div className={`navigation ${active ? "show-nav" : ""}`}>
        <div className={`menu-section ${active ? "active" : ""}`}>
          <nav>
            <ul>{headerMenu}</ul>
          </nav>
        </div>

        {authContext.loggedIn ? (
          <>
            <Link to="/account" className="sign-up">
              {t("account")}
            </Link>
            <Link to="#" onClick={onLogout} className="sign-up">
              {t("logout")}
            </Link>
          </>
        ) : (
          <Link to="/login" className="sign-up">
            {t("login")}
          </Link>
        )}
        <div className="translate-head">
          <ul className="languages">
            {languages.map((lng) => {
              let textPrint = "";
              switch (lng) {
                case "en":
                  textPrint = "ENG";
                  break;
                case "es":
                  textPrint = "ESP";
                  break;
                default:
                  textPrint = "ENG";
              }
              return (
                <li key={lng}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      changeLanguage(lng);
                    }}
                  >
                    {textPrint}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <Link to="#" className="menu-icon" onClick={menuOpen}>
          <div className="show-nav-btn">
            <span></span>
          </div>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default HeaderNavigation;
