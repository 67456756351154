export const getAthleteName = (
  athlete: { firstName: string; lastName?: string },
  possessive?: boolean
): string => {
  const name = `${athlete.firstName}${
    athlete.lastName ? ` ${athlete.lastName}` : ``
  }`;
  if (possessive) {
    return name.substr(-1) === "s" ? `${name}'` : `${name}'s`;
  }
  return name;
};

export const textBetweenMarkers = (text, begin, end) => {
  var firstChar = text.indexOf(begin) + begin.length;
  var lastChar = text.indexOf(end);
  var resultText = text.substring(firstChar, lastChar);
  return resultText;
};
