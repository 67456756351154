import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
//import { Link } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { textBetweenMarkers } from "../../lib/helpers";
import { navigateTo } from "../../lib/UrlUtils";
import HeaderNavigation from "../headerNavigation/headerNavigation";
import "./header.scss";

const Header = ({ menu, ribbon = null, hasBanner }) => {
  const [headerScrolledClass, setHeaderScrolledClass] = useState("");
  let menuTop = useRef(null);

  useEffect(() => {
    const headerOnScroll = () => {
      if (window.pageYOffset > 5) {
        setHeaderScrolledClass("scrolled");
      } else {
        setHeaderScrolledClass("");
      }
    }
    window.addEventListener("scroll", headerOnScroll);
    return () => window.removeEventListener("scroll", headerOnScroll);
  });

  useEffect(() => {
    gsap.from(menuTop.current, { duration: 0.75, y: -100, opacity: 0 });
  }, []);

  let headerClass = "";
  let ribbonClass = "";
  if (hasBanner) {
    headerClass = hasBanner;
  }

  let couponCode = "";
  if (ribbon) {
    ribbonClass = "has-ribbon";
    couponCode = textBetweenMarkers(ribbon.heading, "<span>", "</span>");
  }

  const ribbonClick = () => {
    navigateTo(`/subscribe/?coupon=${couponCode}`);
  };

  return (
    <>
      <header className={`header ${headerScrolledClass} ${headerClass}`}>
        {/* //TODO: Fix space when ther is no ribbon */}
        {ribbon && ribbon.heading && (
          <div className="header-top">
            <div className="container">
              <p className="ribbon-content" onClick={ribbonClick}>
                {parse(ribbon.heading)}
              </p>
            </div>
          </div>
        )}
        <div className="header-bottom">
          <div className="container" ref={menuTop}>
            <div className="logo">
              <Link to="/">
                <BannerLogo banner={hasBanner} />
              </Link>
            </div>
            <HeaderNavigation menu={menu} />
          </div>
        </div>
      </header>
      {
        /* give height when no banner */
        hasBanner ? (
          <div className={`header-placeholder ${ribbonClass}`}></div>
        ) : (
          ""
        )
      }
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

const BannerLogo = ({ banner }) => {
  switch (banner) {
    case "no-banner":
      return (
        <>
          <StaticImage
            className="mobile-logo"
            alt="Fitplan"
            src="../../images/fitplan-logo-mobile.png"
            placeholder="none"
            height={28}
          />
          <StaticImage
            className="desktop-logo"
            alt="Fitplan"
            src="../../images/fitplan-logo.png"
            placeholder="none"
            height={21}
          />
        </>
      );

      case "white-banner":
        return (
          <>
          <StaticImage
            className="mobile-logo"
            alt="Fitplan"
            src="../../images/fitplan-logo-mobile-blk.png"
            //src="../../images/fitplan-logo-mobile.png"
            placeholder="none"
            height={28}
          />
          <StaticImage
            className="desktop-logo"
            alt="Fitplan"
            src="../../images/fitplan-logo-desktop-blk.png"
            //src="../../images/fitplan-logo.png"
            placeholder="none"
            height={21}
          />
        </>
        );

    default:
      return (
        <>
          <StaticImage
              className="mobile-logo"
              alt="Fitplan"
              src="../../images/fitplan-logo-mobile.png"
              placeholder="none"
              height={28}
          />
          <StaticImage
              className="desktop-logo"
              alt="Fitplan"
              src="../../images/fitplan-logo.png"
              placeholder="none"
              height={21}
          />
        </>
      );
  }
};

export default Header;
