import React, { useState } from "react";
import { Link } from "gatsby";
import { Link as TranslatedLink, useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import CookieBanner from "../cookiesBanner";
import Accordion from "../accordion/accordion";
import "./footer.scss";
import { isValidHttpUrl } from "../../lib/UrlUtils";

const Footer = ({ menus }) => {
  const { t, language } = useTranslation();
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="logo-section">
            <TranslatedLink to="/" className="footer-logo">
              <StaticImage
                src="../../images/fitplan-logo.png"
                alt="Fitplan"
                placeholder="blurred"
                layout="fullWidth"
              />
            </TranslatedLink>
            <p>
              {t("footerText1")}
              <br />
              {t("footerText2")}
            </p>
            <div className="social-icons">
              <Link to="https://www.instagram.com/fitplan_app/" target="_blank">
                <StaticImage
                  src="../../images/icons/instagram.svg"
                  height={12}
                  placeholder="blurred"
                  alt="Instagram"
                />
              </Link>
              <Link to="https://www.facebook.com/fitplaninc/" target="_blank">
                <StaticImage
                  src="../../images/icons/facebook.png"
                  height={12}
                  placeholder="blurred"
                  alt="Facebook"
                />
              </Link>
              <Link
                to="https://www.youtube.com/channel/UCREG7CKg5rx4Pl-FlplqXrA"
                target="_blank"
              >
                <StaticImage
                  src="../../images/icons/youtube.png"
                  height={12}
                  placeholder="blurred"
                  alt="Youtube"
                />
              </Link>
              {/* <Link to="#">
                <StaticImage
                  src="../../images/icons/pintrest.png"
                  height={12}
                  placeholder="blurred"
                  alt="Pintrest"
                />
              </Link> */}
            </div>
          </div>
          <div className="footer-menu">
            {menus.nodes.map(({ id, heading, items }) => (
              <AccordionFooter
                key={id}
                id={id}
                heading={heading}
                items={items}
              />
            ))}
          </div>
          <hr />
          <p className="copy-right">Copyright © 2024 Fitplan Technologies, Inc.</p>
        </div>
      </footer>
      <CookieBanner />
    </>
  );
};

const AccordionFooter = ({ id, heading, items }) => {
  const [isActive, setIsActive] = useState(false);
  let activeClass = "";
  if (isActive) {
    activeClass = "active";
  }

  const footerMenu = items?.map((item) => {
    const footerLink = isValidHttpUrl(item.link) ? (
      <a target="_blank" href={`${item.link}`}>
        {item.linkText}
      </a>
    ) : (
      <Link to={`/${item.link}`}>{item.linkText}</Link>
    );
    return <li key={item.id}>{footerLink} </li>;
  });

  return (
    <>
      <div key={id} className={`menu-wrap ${activeClass}`}>
        <h5 className="head-element" onClick={() => setIsActive(!isActive)}>
          {heading}
        </h5>
        <ul className="set-menu">{footerMenu}</ul>
      </div>
    </>
  );
};
export default Footer;
