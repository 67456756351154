import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Link } from "gatsby-plugin-react-i18next";
import "./cookieBanner.scss";

const CookieBanner = () => {
  const [cookieStatus, setCookieStatus] = useState(false);

  const acceptButton = () => {
    Cookies.set("gatsby-gdpr-google-analytics", "true", { expires: 365 });
    Cookies.set("gatsby-gdpr-google-tagmanager", "true", { expires: 365 });
    Cookies.set("gatsby-gdpr-facebook-pixel", "true", { expires: 365 });
    setCookieStatus(true);
  };

  useEffect(() => {
    if (
      Cookies.get("gatsby-gdpr-google-analytics") ||
      Cookies.get("gatsby-gdpr-google-tagmanager") ||
      Cookies.get("gatsby-gdpr-facebook-pixel")
    ) {
      document.querySelector(".cookie-banner").style.display = "none";
    } else {
      document.querySelector(".cookie-banner").style.display = "block";
    }
  }, [cookieStatus]);

  return (
    <>
      <section className="cookie-banner">
        <div className="container">
          <p>
            We use cookies and other tracking technologies to improve your
            browsing experience on our website, to analyze our website traffic,
            and to understand where our visitors are coming from. By browsing
            our website, you consent to our use of cookies and other tracking
            technologies, in accordance with our{" "}
            <a href={`/privacy-policy`} target="_blank">
              Privacy Policy
            </a>
          </p>
          <button type="button" onClick={acceptButton}>
            ACCEPT
          </button>
        </div>
      </section>
    </>
  );
};

export default CookieBanner;
